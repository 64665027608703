<template>
    <div>
        <s-toolbar dense dark color="#7b7b7b" @save="savePersons()" @close="close()" save close label="Motivos Paradas"></s-toolbar>
        <v-card class="pa-md-4 mx-lg-auto">
            <v-row>
                <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                    <v-card-title class="primary--text">Datos Generales</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-text
                                        label="Responsable"
                                        v-model="item.CrpDescription"
                                        ref="txtResponsable"
                                    ></s-text>
                                </v-col>

                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-select-definition
                                        label="Estado"
                                        v-model="item.CrpState"
                                        :def="1154"
                                    ></s-select-definition>
                                </v-col>
                            </v-row>
                            <v-row class="pt-3 pr-3 pl-3">
                                <h3>Detalle de Motivos</h3>
                                <v-spacer></v-spacer>
                                <v-btn rounded fab x-small @click="addItemGP()"
                                    ><i class="fas fa-plus"></i
                                ></v-btn>   
                            </v-row>
                            <v-divider></v-divider>
                            <v-container>
                                <v-row>
                                    
                                    <v-col cols="4" lg="4" md="6" sm="12">
                                        <s-text
                                            label="Motivo"
                                            v-model.trim="newReason.RsdReason"
                                            ref="txtMotivo"
                                        ></s-text>
                                    </v-col>
                                    

                                </v-row>
                                <v-row>
                                    <v-col class="pl-0 pt-0">
                                        <v-data-table
                                            dense
                                            :headers="headerProductionGroup"
                                            :items="itemsDataPG"
                                            hide-default-footer
                                            :items-per-page="-1"
                                            disable-sort
                                        >
                                            <template v-slot:item.Actions="{ item }">
                                                <v-btn
                                                    @click="clickDeleteItemGP(item)"
                                                    x-small
                                                    fab
                                                    rounded
                                                    color="error"
                                                >
                                                <v-icon style="font-size: 16px !important"
                                                >mdi-close</v-icon
                                                >
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import _sFrzCtnResponsibleStopService from "@/services/FrozenProduction/FrzCtnResponsibleStopService.js";
   
    export default {
        components:{},
        props:{
            CrpID:{
                // required: false,
                Type: Number,
            },
            selectGroup:{
                required: false,
                default: Array,
            }
        },
        data() {
            return {
                headerProductionGroup: [
                    { text:"ID", value :"Line"},
                    { text: "Descripción", value: "RsdReason"},
                    { text: "Eliminar", value: "Actions", width: 100},
                ],
                newReason: {},
                itemsDataPG:[],
                itemsDeletePG: [],
                item: {},
                PdgNameGroup: '',
            }
        },
        watch:{
            value(){
                this.getdataPersons();
            }
        }, 
        created(){
            this.getdataPersons();
        },
        methods:
        {
            close()
            {
                this.$emit("close");     
            },
            
            addItemGP(){
                let existReason = false;
                this.itemsDataPG.forEach(element => {
                    if(element.RsdReason == this.newReason.RsdReason){
                        existReason = true;
                        console.log('Motivo ya existe ',existReason)
                    }
                });
                if (existReason){
                    this.$fun.alert("Motivo ya Ingresado", "warning");
                    return ;
                }
                 if (!this.newReason.RsdReason) {
                    this.$refs.txtMotivo.error("Debe ingresar un Motivo");
                    return;
                 } else {
                    this.newReason.Line = this.itemsDataPG.length + 1;
                    this.newReason.SecStatus = 1;
                    this.itemsDataPG.push({...this.newReason});
                    this.newReason = {};

                    this.RsdReason = "";
                    this.$refs.txtMotivo.focus();
                    
                }
            },
            
            clickDeleteItemGP(item) {
                
                this.itemsDataPG = this.itemsDataPG.filter(
                    (x) => {
                    return x.Line != item.Line
                    }
                );
                item.SecStatus = 0;
                if(item.RsdID > 0)
                {
                    this.itemsDeletePG.push({ ...item} );                    
                }
                
            },  
            
            savePersons() {

                if(this.item.txtResponsable == "")
                {
                    this.$fun.alert("Ingrese Responsable", "warning");
                    this.$refs.txtResponsable.focus();
                    return;
                }
                
                if(this.itemsDataPG.length <= 0 ){
                    this.$fun.alert("Ingresar Motivos de paradas", "warning");
                    this.$refs.txtMotivo.focus();
                    return;
                }
                
                this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];
                
                this.item.FrzCtnResponsibleStopDetail = this.itemsDataPGAdd;
                this.item.UsrCreateID = this.$fun.getUserID();

                this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                    if(r.value){
                
                        _sFrzCtnResponsibleStopService.save(this.item, this.$fun.getUserID()).then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close");

                            this.itemsDataPG = []
                        }
                        });
                    }
                })
            },
            getdataPersons()
            {
                console.log("value ", this.CrpID);
                // this.item = {}
                if(this.CrpID > 0){

                    if(this.selectGroup.length > 0)
                    {   
                        this.item = this.selectGroup[0];
                    }
                    this.itemsDataPG = []
                    
                    
                    _sFrzCtnResponsibleStopService.listdetail({ CrpID: this.CrpID }, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            r.data.forEach((el, index) => {
                                el.Line = index + 1;
                            });
                            this.itemsDataPG = r.data;
                            console.log('Detalles',this.itemsDataPG)
                        }
                    
                    })
                }else{
                    this.item = {};
                    this.itemsDataPG = [];
                }
            }
        }
    }
</script>