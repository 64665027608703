<template>
	<div>
		<s-crud
			title="Responsable de Paradas"
            ref="gridResponsible"
			:config="config"  
            :filter="filter"       
			add			
			edit
            add-no-function     	
			search-input
            @rowSelected="rowSelected($event)"
            @add="add()"
            @edit="edit()"
		>       
            <template v-slot:CrpState="{ row }">
				<v-chip x-small=""  :color="row.CrpState == 1 ? 'success': 'error'" >
					{{ row.CrpState == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
        	</template>

            <template v-slot:NumberMotivos="{ row }">
				<v-chip x-small=""  :color="row.NumberMotivos > 0 ? 'warning': 'error'" >
					{{ row.NumberMotivos }}
				</v-chip>
        	</template> 
		</s-crud> 
        <v-dialog max-width="auto" v-model="openDialog" persistent v-if="openDialog" width="900">
            <responsible-detail @save="save()" @close="close()" :selectGroup="selectGroup" v-model="RsdID" :CrpID="Resposible"></responsible-detail>
        </v-dialog> 
	</div>
</template>



<script>
	import _sFrzCtnResponsibleStopService from "@/services/FrozenProduction/FrzCtnResponsibleStopService.js";
    import ResponsibleDetail from "./FrzCtnResponsibleStopDetail.vue"
    

	export default {
        components: { ResponsibleDetail },
		data: () =>({

            config: {
                model: {
                    CrpID: "ID",
                    CrpState:"",
                    NumberMotivos:""
                },
                service: _sFrzCtnResponsibleStopService,
                headers: [
                    {text: "ID", value: "CrpID"},
                    {text: "Descripcion", value: "CrpDescription"},
                    {text: "Estado", value: "CrpState"},
                    {text: "N° Motivos", value: "NumberMotivos"}
                ]
            },
            filter: { CrpDescription: ''},
            openDialog: false,
            RsdID: 0,
            selectGroup: [],
            Resposible: 0,

		}),
 
		methods: {

            add()
            {              
                this.Resposible = 0;
                this.selectGroup = [];
                this.openDialog = true;
                console.log('agregar');
            },
            edit(){                             
                this.openDialog = true;
            },
            close(){                
                this.openDialog = false;
                this.RsdID = '';
                this.selectGroup = [];
                this.$refs.gridResponsible.refresh();
            },
            save(){
                this.$refs.gridResponsible.refresh();
            },
            
            rowSelected(item){
                console.log('load');
                // this.Resposible = 0;
                this.selectGroup = item;
                this.RsdID = this.selectGroup.length > 0 ? this.selectGroup[0].CrpID : 0;
                
                if (item[0].CrpID){
                    this.Resposible = item[0].CrpID;
                }
            },
        },
            
	}
</script>